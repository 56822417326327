/* required styles */

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
	position: absolute!important;
	left: 0!important;
	top: 0!important;
	}
.leaflet-container {
	overflow: hidden!important;
	}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
	-webkit-user-select: none!important;
	   -moz-user-select: none!important;
	        user-select: none!important;
	  -webkit-user-drag: none!important;
	}
/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
	background: transparent!important;
}
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
	image-rendering: -webkit-optimize-contrast!important;
	}
/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
	width: 1600px!important;
	height: 1600px!important;
	-webkit-transform-origin: 0 0!important;
	}
.leaflet-marker-icon,
.leaflet-marker-shadow {
	display: block!important;
	}
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
	max-width: none !important;
	max-height: none !important;
	}

.leaflet-container.leaflet-touch-zoom {
	-ms-touch-action: pan-x pan-y!important;
	touch-action: pan-x pan-y!important;
	}
.leaflet-container.leaflet-touch-drag {
	-ms-touch-action: pinch-zoom!important;
	/* Fallback for FF which doesn't support pinch-zoom */
	touch-action: none!important;
	touch-action: pinch-zoom!important;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
	-ms-touch-action: none!important;
	touch-action: none!important;
}
.leaflet-container {
	-webkit-tap-highlight-color: transparent!important;
}
.leaflet-container a {
	-webkit-tap-highlight-color: rgba(51, 181, 229, 0.4)!important;
}
.leaflet-tile {
	filter: inherit!important;
	visibility: hidden!important;
	}
.leaflet-tile-loaded {
	visibility: inherit!important;
	}
.leaflet-zoom-box {
	width: 0!important;
	height: 0!important;
	-moz-box-sizing: border-box!important;
	     box-sizing: border-box!important;
	z-index: 800!important;
	}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
	-moz-user-select: none!important;
	}

.leaflet-pane         { z-index: 400!important; }

.leaflet-tile-pane    { z-index: 200!important; }
.leaflet-overlay-pane { z-index: 400!important; }
.leaflet-shadow-pane  { z-index: 500!important; }
.leaflet-marker-pane  { z-index: 600!important; }
.leaflet-tooltip-pane   { z-index: 650!important; }
.leaflet-popup-pane   { z-index: 700!important; }

.leaflet-map-pane canvas { z-index: 100!important; }
.leaflet-map-pane svg    { z-index: 200!important; }

.leaflet-vml-shape {
	width: 1px!important;
	height: 1px!important;
	}
.lvml {
	behavior: url(#default#VML)!important;
	display: inline-block!important;
	position: absolute!important;
	}


/* control positioning */

.leaflet-control {
	position: relative!important;
	z-index: 800!important;
	pointer-events: visiblePainted!important; /* IE 9-10 doesn't have auto */
	pointer-events: auto!important;
	}
.leaflet-top,
.leaflet-bottom {
	position: absolute!important;
	z-index: 1000!important;
	pointer-events: none!important;
	}
.leaflet-top {
	top: 0!important;
	}
.leaflet-right {
	right: 0!important;
	}
.leaflet-bottom {
	bottom: 0!important;
	}
.leaflet-left {
	left: 0!important;
	}
.leaflet-control {
	float: left!important;
	clear: both!important;
	}
.leaflet-right .leaflet-control {
	float: right!important;
	}
.leaflet-top .leaflet-control {
	margin-top: 10px!important;
	}
.leaflet-bottom .leaflet-control {
	margin-bottom: 10px!important;
	}
.leaflet-left .leaflet-control {
	margin-left: 10px!important;
	}
.leaflet-right .leaflet-control {
	margin-right: 10px!important;
	}


/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
	will-change: opacity!important;
	}
.leaflet-fade-anim .leaflet-popup {
	opacity: 0!important;
	-webkit-transition: opacity 0.2s linear!important;
	   -moz-transition: opacity 0.2s linear!important;
	        transition: opacity 0.2s linear!important;
	}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
	opacity: 1!important;
	}
.leaflet-zoom-animated {
	-webkit-transform-origin: 0 0!important;
	    -ms-transform-origin: 0 0!important;
	        transform-origin: 0 0!important;
	}
.leaflet-zoom-anim .leaflet-zoom-animated {
	will-change: transform!important;
	}
.leaflet-zoom-anim .leaflet-zoom-animated {
	-webkit-transition: -webkit-transform 0.25s cubic-bezier(0,0,0.25,1)!important;
	   -moz-transition:    -moz-transform 0.25s cubic-bezier(0,0,0.25,1)!important;
	        transition:         transform 0.25s cubic-bezier(0,0,0.25,1)!important;
	}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
	-webkit-transition: none!important;
	   -moz-transition: none!important;
	        transition: none!important;
	}

.leaflet-zoom-anim .leaflet-zoom-hide {
	visibility: hidden!important;
	}


/* cursors */

.leaflet-interactive {
	cursor: pointer!important;
	}
.leaflet-grab {
	cursor: -webkit-grab!important;
	cursor:    -moz-grab!important;
	cursor:         grab!important;
	}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
	cursor: crosshair!important;
	}
.leaflet-popup-pane,
.leaflet-control {
	cursor: auto!important;
	}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
	cursor: move!important;
	cursor: -webkit-grabbing!important;
	cursor:    -moz-grabbing!important;
	cursor:         grabbing!important;
	}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
	pointer-events: none!important;
	}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
	pointer-events: visiblePainted!important; /* IE 9-10 doesn't have auto */
	pointer-events: auto!important;
	}

/* visual tweaks */

.leaflet-container {
	background: #ddd!important;
	outline: 0!important;
	}
.leaflet-container a {
	color: #0078A8!important;
	}
.leaflet-container a.leaflet-active {
	outline: 2px solid orange!important;
	}
.leaflet-zoom-box {
	border: 2px dotted #38f!important;
	background: rgba(255,255,255,0.5)!important;
	}


/* general typography */
.leaflet-container {
	font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif!important;
	}


/* general toolbar styles */

.leaflet-bar {
	box-shadow: 0 1px 5px rgba(0,0,0,0.65)!important;
	border-radius: 4px!important;
	}
.leaflet-bar a,
.leaflet-bar a:hover {
	background-color: #fff!important;
	border-bottom: 1px solid #ccc!important;
	width: 26px!important;
	height: 26px!important;
	line-height: 26px!important;
	display: block!important;
	text-align: center!important;
	text-decoration: none!important;
	color: black!important;
	}
.leaflet-bar a,
.leaflet-control-layers-toggle {
	background-position: 50% 50%!important;
	background-repeat: no-repeat!important;
	display: block!important;
	}
.leaflet-bar a:hover {
	background-color: #f4f4f4!important;
	}
.leaflet-bar a:first-child {
	border-top-left-radius: 4px!important;
	border-top-right-radius: 4px!important;
	}
.leaflet-bar a:last-child {
	border-bottom-left-radius: 4px!important;
	border-bottom-right-radius: 4px!important;
	border-bottom: none!important;
	}
.leaflet-bar a.leaflet-disabled {
	cursor: default!important;
	background-color: #f4f4f4!important;
	color: #bbb!important;
	}

.leaflet-touch .leaflet-bar a {
	width: 30px!important;
	height: 30px!important;
	line-height: 30px!important;
	}
.leaflet-touch .leaflet-bar a:first-child {
	border-top-left-radius: 2px!important;
	border-top-right-radius: 2px!important;
	}
.leaflet-touch .leaflet-bar a:last-child {
	border-bottom-left-radius: 2px!important;
	border-bottom-right-radius: 2px!important;
	}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
	font: bold 18px 'Lucida Console', Monaco, monospace!important;
	text-indent: 1px!important;
	}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out  {
	font-size: 22px!important;
	}


/* layers control */

.leaflet-control-layers {
	box-shadow: 0 1px 5px rgba(0,0,0,0.4)!important;
	background: #fff!important;
	border-radius: 5px!important;
	}
.leaflet-control-layers-toggle {
	background-image: url(images/layers.png)!important;
	width: 36px!important;
	height: 36px!important;
	}
.leaflet-retina .leaflet-control-layers-toggle {
	background-image: url(images/layers-2x.png)!important;
	background-size: 26px 26px!important;
	}
.leaflet-touch .leaflet-control-layers-toggle {
	width: 44px!important;
	height: 44px!important;
	}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
	display: none!important;
	}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
	display: block!important;
	position: relative!important;
	}
.leaflet-control-layers-expanded {
	padding: 6px 10px 6px 6px!important;
	color: #333!important;
	background: #fff!important;
	}
.leaflet-control-layers-scrollbar {
	overflow-y: scroll!important;
	overflow-x: hidden!important;
	padding-right: 5px!important;
	}
.leaflet-control-layers-selector {
	margin-top: 2px!important;
	position: relative!important;
	top: 1px!important;
	}
.leaflet-control-layers label {
	display: block!important;
	}
.leaflet-control-layers-separator {
	height: 0!important;
	border-top: 1px solid #ddd!important;
	margin: 5px -10px 5px -6px!important;
	}

/* Default icon URLs */
.leaflet-default-icon-path {
	background-image: url(images/marker-icon.png)!important;
	}


/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
	background: #fff!important;
	background: rgba(255, 255, 255, 0.7)!important;
	margin: 0!important;
	}
.leaflet-control-attribution,
.leaflet-control-scale-line {
	padding: 0 5px!important;
	color: #055ea9!important;
	}
.leaflet-control-attribution a {
	text-decoration: none!important;
	}
.leaflet-control-attribution a:hover {
	text-decoration: underline!important;
	}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
	font-size: 15px!important;
	}
.leaflet-left .leaflet-control-scale {
	margin-left: 15px!important;
	}
.leaflet-bottom .leaflet-control-scale {
	margin-bottom: 5px!important;
	}
.leaflet-control-scale-line {
	border: 2px solid #777!important;
	border-top: none!important;
	line-height: 1.1!important;
	padding: 2px 5px 1px!important;
	font-size: 18px!important;
	white-space: nowrap!important;
	overflow: hidden!important;
	-moz-box-sizing: border-box!important;
	     box-sizing: border-box!important;

	background: #fff!important;
	background: rgba(255, 255, 255, 0.5)!important;
	}
.leaflet-control-scale-line:not(:first-child) {
	border-top: 2px solid #777!important;
	border-bottom: none!important;
	margin-top: -2px!important;
	}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
	border-bottom: 2px solid #777!important;
	}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	box-shadow: none!important;
	}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	border: 2px solid rgba(0,0,0,0.2)!important;
	background-clip: padding-box!important;
	}


/* popup */

.leaflet-popup {
	position: absolute!important;
	text-align: center!important;
	margin-bottom: 20px!important;
	}
.leaflet-popup-content-wrapper {
	padding: 1px!important;
	text-align: left!important;
	border-radius: 12px!important;
	}
.leaflet-popup-content {
	margin: 13px 19px!important;
	line-height: 1.4!important;
	}
.leaflet-popup-content p {
	margin: 18px 0!important;
	}
.leaflet-popup-tip-container {
	width: 40px!important;
	height: 20px!important;
	position: absolute!important;
	left: 50%!important;
	margin-left: -20px!important;
	overflow: hidden!important;
	pointer-events: none!important;
	}
.leaflet-popup-tip {
	width: 17px!important;
	height: 17px!important;
	padding: 1px!important;

	margin: -10px auto 0!important;

	-webkit-transform: rotate(45deg)!important;
	   -moz-transform: rotate(45deg)!important;
	    -ms-transform: rotate(45deg)!important;
	        transform: rotate(45deg)!important;
	}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
	background: rgba(255, 255, 255, 0.90)!important;
	font-size: 14px;
	color: rgb(35, 29, 116)!important;
	/*box-shadow: 0 3px 14px rgba(255,255,255,0)!important;*/
	/*color: rgb(255, 255, 255)!important;*/
	/*text-shadow: 1px 1px  rgb(219, 219, 219)!important;*/
	}
.leaflet-container a.leaflet-popup-close-button {
	position: absolute!important;
	top: 0!important;
	right: 0!important;
	padding: 4px 4px 0 0!important;
	border: none!important;
	text-align: center!important;
	width: 18px!important;
	height: 14px!important;
	font: 16px/14px Tahoma, Verdana, sans-serif!important;
	color: #c3c3c3!important;
	text-decoration: none!important;
	font-weight: bold!important;
	background: transparent!important;
	}
.leaflet-container a.leaflet-popup-close-button:hover {
	color: #999!important;
	}
.leaflet-popup-scrolled {
	overflow: auto!important;
	border-bottom: 1px solid #ddd!important;
	border-top: 1px solid #ddd!important;
	}

.leaflet-oldie .leaflet-popup-content-wrapper {
	zoom: 1!important;
	}
.leaflet-oldie .leaflet-popup-tip {
	width: 24px!important;
	margin: 0 auto!important;

	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)"!important;
	filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)!important;
	}
.leaflet-oldie .leaflet-popup-tip-container {
	margin-top: -1px!important;
	}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
	border: 1px solid #999!important;
	}


/* div icon */

.leaflet-div-icon {
	background: #fff!important;
	border: 1px solid #666!important;
	}


/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
	position: absolute!important;
	padding: 6px!important;
	background-color: #fff!important;
	border: 1px solid #fff!important;
	border-radius: 3px!important;
	color: #222!important;
	white-space: nowrap!important;
	-webkit-user-select: none!important;
	-moz-user-select: none!important;
	-ms-user-select: none!important;
	user-select: none!important;
	pointer-events: none!important;
	box-shadow: 0 1px 3px rgba(0,0,0,0.4)!important;
	}
.leaflet-tooltip.leaflet-clickable {
	cursor: pointer!important;
	pointer-events: auto!important;
	}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
	position: absolute!important;
	pointer-events: none!important;
	border: 6px solid transparent!important;
	background: transparent!important;
	content: ""!important;
	}

/* Directions */

.leaflet-tooltip-bottom {
	margin-top: 6px!important;
}
.leaflet-tooltip-top {
	margin-top: -6px!important;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
	left: 50%!important;
	margin-left: -6px!important;
	}
.leaflet-tooltip-top:before {
	bottom: 0!important;
	margin-bottom: -12px!important;
	border-top-color: #fff!important;
	}
.leaflet-tooltip-bottom:before {
	top: 0!important;
	margin-top: -12px!important;
	margin-left: -6px!important;
	border-bottom-color: #fff!important;
	}
.leaflet-tooltip-left {
	margin-left: -6px!important;
}
.leaflet-tooltip-right {
	margin-left: 6px!important;
}
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
	top: 50%!important;
	margin-top: -6px!important;
	}
.leaflet-tooltip-left:before {
	right: 0!important;
	margin-right: -12px!important;
	border-left-color: #fff!important;
	}
.leaflet-tooltip-right:before {
	left: 0!important;
	margin-left: -12px!important;
	border-right-color: #fff!important;
	}
	.leaflet-container .leaflet-control-mouseposition {
		background-color: rgba(255, 255, 255, 1)!important;
		box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
		border:2px solid #000!important;
		padding: 0 5px;
		border-radius: 5px;
		margin:0;
		color: #055EA9;
		font: 16px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
	  }